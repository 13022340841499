.comparePopUpBlock{
    display: flex;
    gap: 25px;
    .compareCard{
        flex: 0 0 33%;
        text-align: center;
        display: flex;
        flex-direction: column;
        gap: 15px;
        border-radius: 15px;
        position: relative;
        box-shadow: 0 0 0 #000, 0 0 20px 0px #ececec;
        padding: 15px 15px 70px;
        .productImageBlock_{
            padding-top: 50%;
            position: relative;
            img{
                position: absolute;
                left: 50%;
                transform: translate(-50%, -50%);
                height: 100%;
                object-fit: cover;
                top: 50%;
            }
            div{
                position: static!important;
            }
            .rootForImage{

            }
        }
        .title{
            min-height: 50px; 
        }
        p{
            margin: 0;
            font-size: 14px;
            line-height: normal;
            min-height: 70px;
        }
        .comparelistPoints{
                p{
                    line-height: normal;
                    font-size: 12px;
                    padding: 11px 0;
                    min-height: 53px;
                    &:not(:last-child){
                        border-bottom: 1px solid #f2f2f2;
                    }
                }
        }
        .campareProdBtn{
            position: absolute;
    bottom: 15px;
    width: 100%;
        }

       &:first-child{
        .comparelistPoints{
          
            p{
                position: relative;
                /* &.monthPrice{
                    &:after{
                        content: 'Price Per Month:';
                    }
                }
                &.careTypes{
                    &:after{
                        content: 'Care types offered:';
                    }
                }
                &.amenities_services{
                    &:after{
                        content: 'Amenities:';
                    }
                }
                &.bedcapacity{
                    &:after{
                        content: 'Size (# of beds):';
                    }
                } */

                span{
                    
                    position: absolute;
                    width: 100%;
                    height: auto;
                    left: -86%;
                     background: transparent;
                     text-align: left;
                }
            }
        }
       } 
    }
    @media screen and (width < 767px){
        /* flex-direction: column; */
    }
}
.customModel{

}

.comparePointsList{
    flex: 0 0 16%;
    visibility: hidden;
    min-width: 182px;
    .pointsList{
        /* padding-top: 12.396vw; */
    text-align: left;
    p{
        font-size: 14px;
        text-align: left;
        line-height: normal;
                font-size: 12px;
                padding: 11px 0;
                margin: 0;
    }
    }
}
.compareListCards{
    display: flex;
    gap: 25px;
    .compareCard{
        max-width: calc(100% / 3 - 16px);
        min-width: 278px;
    }
}