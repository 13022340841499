@import '../../../styles/customMediaQueries.css';

.root {
  width: 100%;  
  
  background-color: var(--colorGrey100);
    /* margin-top: 72px; */
}

.mapRoot {
  width: 100%;
  height: 100%;
}

.reusableMap {
  width: 100%;
  height: 100vh;
  position: sticky;
  left: 50%;
  top: 75px;
  max-width: 22%;
  @media screen and (width < 1200px){
    max-width: 100%;
  }
}

.defaultMapLayout {
  position: fixed;
  top: 0;
  right: 0;
  width: 50vw;
  height: 100vh;
}

/**
 * When reusable map is attached right to the body it's hidden.
 * Specificity rule is added to overwrite positioning coming from props.className
 */
body > .reusableMapHidden {
  position: absolute;
  top: -1000px;
  left: -1000px;
  visibility: hidden;
  opacity: 0;

  @media (--viewportMedium) {
    top: -1000px;
    left: -1000px;
    right: auto;
  }
}


