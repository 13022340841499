@import '../../styles/customMediaQueries.css';

.topbar {
  width: 100%;

  /* Top Bar's drop shadow should always be visible */
  z-index: var(--zIndexTopbar);
  position: relative;

  @media (--viewportMedium) {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    /* We need to raise Topbar above .container */
    z-index: var(--zIndexTopbar);
  }
}

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.container {
  max-width: 1270px;
  padding: 0 15px;
  margin: 0 auto 50px;
  width: 100%;
  @media screen and (max-width: 1440px) {
    max-width: 1200px;
  }
  &:has(.showMobileFilter){
    .filterIconBlock{
      left: 400px;
      z-index: 9999;
      @media screen and (width < 550px){
        left: 80%;
      }
    }
  }
}

/* Container for side and content */
.layoutWrapperContainer {
  composes: container;
  /* parent aka root is flexbox, this container takes all available space */
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  flex-direction: column;
  width: 100%;

  /* When side nav is shown as a column, Topbar's box-shadow should be visible */
  z-index: calc(var(--zIndexTopbar) - 1);

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: center;
  }
}

.layoutWrapperFilterColumn {
  /* display: none; */

  @media (--viewportMedium) {
    /* Layout */
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 24px 0 0 24px;
  }
  @media (--viewportLarge) {
    /* Layout */
    padding: 40px 0 0 36px;

    flex-direction: column;
    justify-content: flex-start;
    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  @media (min-width: 1440px) {
    padding: 40px 0 0 calc((100vw - 1368px) / 2);
  }
  @media (--viewportXLarge) {
    padding: 40px 0 0 calc((100vw - 1800px) / 2);
  }
}

.filterColumnContent {
  width: 210px;
  padding: 8px 24px 0 0;

  @media (--viewportMedium) {
    padding: 8px 24px 24px 0;
  }

  @media (--viewportLarge) {
    width: 240px;
    padding: 8px 36px 24px 0;
  }

  @media (min-width: 1440px) {
    padding: 8px 60px 24px 0;
  }
}
.filter:first-child {
  padding-top: 0;
}

.resetAllButton {
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey300);

  /* Layout */
  display: inline;
  margin: 17px 0 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }

  @media (--viewportMedium) {
    margin: 24px 0 0 0;
    padding: 3px 0 5px 0;
  }
}

.layoutWrapperMain {
  /* Expand to the full remaining width of the viewport */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 0 24px 0 24px;

  @media (--viewportMedium) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    padding: 24px 24px 0 24px;
    background-color: var(--colorWhite);
    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: var(--colorGrey100);
  }

  @media (--viewportLarge) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    padding: 40px 36px 0 36px;
  }

  @media (min-width: 1440px) {
    padding: 40px calc((100vw - 1368px) / 2) 0 59px;
  }
  @media (--viewportXLarge) {
    padding: 40px calc((100vw - 1800px) / 2) 0 59px;
  }
}

.searchResultContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  padding: 2px 0 0 0;

  @media (--viewportMedium) {
    padding: 0;
    /**
     * .container is using flexbox,
     * This specifies that searchResultContainer is taking 60% from the viewport width
     */
    flex-basis: 50%;
  }

  @media (--viewportLarge) {
    flex-basis: 62.5%;
  }
}

.error {
  color: var(--colorFail);
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.mainPanel {
  display: none;

  @media (--viewportMedium) {
    display: flex;
  }
}

.mainPanelMapVariant {
  composes: mainPanel;

  @media (--viewportMedium) {
    padding: 18px 24px 0 24px;
    margin-bottom: 21px;
  }

  @media (--viewportLarge) {
    padding: 23px 36px 0 36px;
  }
}

.searchFiltersMobileMap {
  padding: 16px 24px;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersMobileList {
  padding: 16px 0;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

.listings {
  display: flex;
    flex-direction: column;
    flex: 0 0 85%;
    max-width: calc(100% - 22%);
  @media screen and (width < 1200px){
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.listingsForGridVariant {
  composes: listings;
}

.listingsForMapVariant {
  composes: listings;
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.newSearchInProgress {
  opacity: 0.1;
}

.searchListingsPanel {
  flex-grow: 1;
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 37.5%;
  }
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100vw;
  height: 100vh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    /* position: fixed; */
    top: var(--topbarHeightDesktop);
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 50vw;
    height: calc(100vh - var(--topbarHeightDesktop));
  }

  @media (--viewportLarge) {
    width: 100%;
  }
}

.headerContainer {
  margin-top: 64px;
  .headerWrapper {
    margin-bottom: 64px;
    text-align: center;
    h2{
      span{
        color: var(--marketplaceColor);
      }
    }
    p{
      color: #666666;
    }
  }
  .searcBarM {
    border: 1px solid #262bba40;
    border-radius: 16px;
  }
}
.subHeaderContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 64px 0;
  .leftContent {
    h3 {
      font-family: Sora;
      font-size: 24px;
      font-weight: 600;
      line-height: 33.6px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      color: #0b0f0e;
    }
    .resultsFound {
      font-family: Sora;
      font-size: 16px;
      font-weight: 400;
      line-height: 25.6px;
      color: #818b9c;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
  }
  .rightContent {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .filtersWrapper {
      padding-right: 24px;
      .sortyByWrapper {
        display: flex;
        align-items: center;
        gap: 8px;
        .sortyBy {
          span {
            font-family: Sora;
            font-size: 16px;
            font-weight: 400;
            line-height: 25.6px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #818b9c;
          }
        }
        button {
          &:is(:hover, :focus) {
            box-shadow: unset;
            border: 1px solid #e5e5e6;
          }
        }
      }
      @media screen and (width < 767px){
        padding: 0;
      }
    }
    .viewManager {
      padding-left: 24px;
      position: relative;
      display: flex;
      gap: 8px;
      &::before {
        content: '';
        position: absolute;
        width: 1px;
        height: 24px;
        background-color: #e4e9ee;
        left: 0;
        top: 12px;
      }
      button {
        border: 0;
        padding: 0;
        background-color: #fff;
        cursor: pointer;
        svg {
          fill: #ffffff;
          transition: fill 0.2s linear;
        }
        &:first-child {
          &:not(.active) {
            border: 1px solid #e4e9ee;
            border-radius: 8px;
            svg {
              rect {
                fill: #fff !important;
                stroke: transparent;
              }
              path {
                fill: var(--marketplaceColor);
              }
            }
          }
        }

        &:is(.active) {
          svg {
            fill: var(--marketplaceColor);
            rect {
              stroke: var(--marketplaceColor);
            }
            path {
              stroke: #ffffff;
            }
          }
        }
        /* &:first-child{
          svg{
             rect{
              fill: #fff!important;
             }
             path{

             }
          }
        } */
        @media screen and (width <= 620px) {
          &:nth-child(2){
            display: none;
          }
         }
      }
      @media screen and (width < 767px){
        padding: 0;
        &::before{
          display: none;
        }
      }
    }
  
    @media screen and (width <= 620px){
      justify-content: flex-start;
    margin-top: 24px;
    }
  }
  @media screen and (width <= 620px){
    grid-template-columns: repeat(1, 1fr);
  }
}

.searchWrapperContainer {
  display: flex;
  gap: 40px;
  .layoutWrapperFilterColumn {
    padding: 0;
    .filterColumnContent {
      width: 278px;
      padding: 24px;
      border: 1px solid #e4e9ee;
      border-radius: 12px;
      .filterHeading {
        /* font-family: Clash Grotesk; */
        font-size: 20px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: -0.20000000298023224px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        color: #0b0f0e;
        border-bottom: 1px solid #e4e9ee;
        padding-bottom: 24px;
        margin-bottom: 9px;
      }
      .filter {
        border-bottom: 1px solid #e4e9ee;
      }
      button {
        &.applyFilterButton {
          min-height: 40px;
        }
      }
    }
    .filterHeader {
      padding: 15px 0;
    }

    @media screen and (width < 992px) {
      position: fixed;
      z-index: 999;
      background: #fff;
      top: 70px;
      left: 0;
      width: 100%;
      background: rgb(0 0 0 / 71%);
      left: -100%;
      transition: left 0.2s linear;
      .filterColumnContent {
        background-color: #ffffff;
        border-radius: 0;
        width: 100%;
        max-width: 400px;
        height: calc(100vh - 70px);
        overflow: auto;
      }
      &.showMobileFilter{
        left: 0 ;
      }
    }
    @media screen and (width < 767px){
    
      top: 0;
      height: 100vh;
      .filterColumnContent {       
        height: 100vh;
      }
    }
    @media screen and (width < 550px){
    
      .filterColumnContent {       
        max-width: 80%;
      }
    }
  }

  .listingsForGridVariant {
    max-width: calc(100% - 318px);
    flex: 0 0 100%;
  }

  @media screen and (width < 1200px) {
    gap: 15px;
  }
  .listingsForGridVariant {
    max-width: calc(100% - 318px);
    flex: 0 0 100%;
    @media screen and (width < 1200px) {
      max-width:calc(100% - 293px);
     }
    @media screen and (width < 992px) {
     max-width: 100%;
    }
  }
}



.filterIconBlock{
  position: fixed;
  left: 0;
  z-index: 99;
  background: var(--marketplaceColor);
  padding: 8px;
  border-radius: 12px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  cursor: pointer;
  top: 51%;
  transition: left 0.2s linear;
  svg{
    fill: #ffffff;
    path{
      stroke: #ffffff;
    }
  }
  @media screen and (width > 992px){
    display: none;
  }
}


.addMapBlock{
  width: 99vw;
  transform: translateX(-50%);
  margin-left: 50%;
  display: flex;
  justify-content: center;
  padding: 0 15px;
  .mapProductsBlock{
    display: flex;
    flex-direction: row-reverse;
    flex: 1;
    flex-wrap: wrap;
    .tabForMobile{
        flex: 0 0 100%;
        border-bottom: 1px solid black;
        max-width: calc(100% - 17px);
        margin: 15px auto;
        display: none;        
        button{
          background-color: transparent;
          border: 0;
                padding: 8px 15px;
                border-top-left-radius: 16px;
                border-top-right-radius: 16px;
          
          &.activeTabB{
            background-color: #262bba;
            color: #fff;
          }
        }
        @media screen and (width < 1200px){
          display: block;
        } 
    }
    @media screen and (width < 1200px){
      display: block;
                max-width: 100%;
                width: 100%;
    }
  }
  @media screen and (width < 992px){
    transform: unset;
    margin: 0;
    width: 100%;
    padding: 0;
  }
  
}