@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.listingCardsMapVariant {
  padding: 0 0 96px 0;

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  width: 100%;

  @media (--viewportLarge) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportXLarge) {
    grid-template-columns: repeat(3, 1fr);
  }
}

.listingCards {
  padding: 0 0 72px 0;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
  width: 100%;

  @media (min-width: 550px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportMedium) {
    padding: 0 0 96px 0;
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportLarge) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1441px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (--viewportXLarge) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (width <= 620px){
    grid-template-columns: repeat(1, 1fr);
  }
}

.listingCard {
  width: 100%;
 
}

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color: transparent;
}

.listView{
  grid-template-columns: repeat(1, 1fr);
  border-bottom: 1px solid #f2f2f2;
  
}


.compareDiv{
  position: fixed;
    z-index: 999;
    bottom: 0;
    width: 100%;
    left: 0;
    background: #fff;
    box-shadow: 0 0 0 #000, 0px -4px 20px #00000021;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 15px;
  .cambareCards{
    display: flex;
    gap: 20px;
    max-width: 1200px;
    margin: 25px auto;
    width: 100%;
      .selectedListingsItems{
        position: relative;       
            box-shadow: 0 0 0 #000, 0 0 10px #dbdbdb;
            border-radius: 12px;
            padding: 15px;
            flex: 0 0 33%;
            max-width: calc(100% / 3 - 13px);
            p{
              color: #000;
              &:nth-child(2){
                font-size: 14px;
              }
            }
            button{
              position: absolute;
                top: 0;
                right: 0;
                background: transparent;
                border: 0;
            }
      }
      @media screen and (width < 575px){
        flex-direction: column;
        .selectedListingsItems{
          max-width: 100%;
        }
      }
  }
}